import React from 'react';
import { formatCardNumber, formatCPF, formatMonth, formatToBRL, formatYear } from '../../utils/maskFunctions';

export const Input = ({ setFormData, formData, label, placeholder, id, type, value, disabled, required, maxLength, typeMask  }) => {
  
  const masks =  {
    "money": formatToBRL,
    "cpf": formatCPF,
    "year": formatYear,
    "card": formatCardNumber,
    "month": formatMonth
  }
  
  const handleInputChange = (e) => {
    var { value } = e.target;

    if (typeMask && masks[typeMask]) {
      value = masks[typeMask](value); 
    }

    setFormData({
      ...formData,
      [id]: value,
    });
  };



  return (
    <div className="flex flex-col mb-4">
      <label className="mb-2 text-sm font-semibold text-white">{label}</label>
      <input
        type={type || 'text'}
        value={value || formData[id] || ''}
        onChange={handleInputChange}
        placeholder={placeholder}
        className="bg-primary-dark rounded-md h-12 p-2 focus:outline-none focus:border-secondary-light text-white"
        disabled={disabled}
        required={required}
        maxLength={maxLength }
      />
    </div>
  );
};


