export function convertData(data) {
    const [datePart, timePart] = data.split('T'); 
    const [ano, mes, dia] = datePart.split('-');
  
    const hora = timePart.split(':').slice(0, 2).join(':');
  
    return `${dia}/${mes}/${ano} às ${hora}`;
  }

export function formatToBRL(value) {
    return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value);
  }

export function formatCardNumber (value){
  return value
    .replace(/\D/g, '') // Remove todos os caracteres não numéricos
    .replace(/(\d{4})(\d)/, '$1 $2') // Adiciona um espaço a cada 4 dígitos
    .replace(/(\d{4})(\d{1,2})/, '$1 $2') // Adiciona um espaço a cada 4 dígitos
    .replace(/(\d{4})(\d{1,2})/, '$1 $2') // Repete para os próximos
    .replace(/(\d{4})(\d{1,2})/, '$1 $2'); // Continua a formatação até o fim
}

export function formatCPF(value){
  return value
    .replace(/\D/g, '') // Remove todos os caracteres não numéricos
    .replace(/^(\d{3})(\d)/, '$1.$2') // Adiciona o ponto após os 3 primeiros números
    .replace(/(\d{3})(\d)/, '$1.$2') // Adiciona o ponto após os 6 primeiros números
    .replace(/(\d{3})(\d{1,2})/, '$1-$2'); // Adiciona o traço entre os dois últimos números
};


export function formatYear(value) {
  return value.replace(/\D/g, '').slice(0, 4); // Limita a 4 dígitos
};


export function formatMonth(value) {
  return value.replace(/\D/g, '').slice(0, 2); // Limita a 2 dígitos
};
