import axios from "axios";
import Cookies from 'js-cookie';

class ApiService {
  constructor(tableName) {
    this.api = axios.create({
      baseURL: process.env.REACT_APP_NEXT_PUBLIC_BASEURLAPI,
      headers: { "Content-Type": "application/json" }
    });
    this.tableName = tableName;
    this.setAuthToken();
  }

  setAuthToken() {
    const token = Cookies.get('token'); 
    if (token) {
      this.api.defaults.headers['Authorization'] = `Bearer ${token}`;
    } else {
      delete this.api.defaults.headers['Authorization']; 
    }
  }

  async getById(Id) {
    try {
      const response = await this.api.get(`/${this.tableName}/${Id}`);
      return response.data;
    } catch (error) {
      console.error("Erro na requisição GET:", error.response || error.message);
      throw error;
    }
  }

  async get(path = "", query = "", params = {}) {
    try {
        let processedPath = path;
        if (params && typeof params === "object") {
            Object.keys(params).forEach(key => { 
                processedPath = processedPath.replace(`:${key}`, params[key]);
            });
        }

        const url = `/${this.tableName}${processedPath ? `/${processedPath}` : ""}${query ? `?${query}` : ""}`;
        const response = await this.api.get(url);
        return response.data;
    } catch (error) {
        console.error("Erro na requisição GET:", error.response || error.message);
        throw error;
    }
  }


  async post(path = "",data) {
    const headers = data?.file
    ? { "Content-Type": "multipart/form-data" }
    : { "Content-Type": "application/json" };
    try {
      const response = await this.api.post(`/${this.tableName}${path ? `/${path}` : ""}`, data, {headers});
      return response.data;
    } catch (error) {
      console.error("Erro na requisição POST:", error.response || error.message);
      throw error;
    }
  }

  async put(id, data) {
    const headers = data?.file
    ? { "Content-Type": "multipart/form-data" }
    : { "Content-Type": "application/json" };
    try {
      const response = await this.api.put(`/${this.tableName}/${id}`, data, {headers});
      return response.data;
    } catch (error) {
      console.error("Erro na requisição PUT:", error.response || error.message);
      throw error;
    }
  }

  async delete(id) {
    try {
      const response = await this.api.delete(`/${this.tableName}/${id}`);
      return response.data;
    } catch (error) {
      console.error("Erro na requisição DELETE:", error.response || error.message);
      throw error;
    }
  }
}

export default ApiService;
